import { lazy } from "react"

/* Páginas */
const LicencasLista = lazy(() => import("../../views/licencas/lista"));
const LicencasForm = lazy(() => import("../../views/licencas/form"));

const licencasRoutes = [
    {
      path: "/licencas",
      element: <LicencasLista />,
      isPrivate: true,
    },
    {
      path: "/licencas/form",
      element: <LicencasForm />,
      isPrivate: true,
    },
    {
      path: "/licencas/form/:id",
      element: <LicencasForm />,
      isPrivate: true,
    },
];

export default licencasRoutes;