// ** React Imports
import { ReactNode } from 'react';
import { Outlet } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom"

import styles from './base.module.scss';

import Menu from "../../components/menu";
import MenuUser from "../../components/menuUser";

// ** Images
import logo from "../../assets/img/logo.png";

type BaseLayoutProps = {
    children?: ReactNode;
  };

  function BaseLayout({ children }: BaseLayoutProps) {

    return (
        <>
            <header>
                <Container className="py-4">
                    <Row className="d-flex align-items-center">
                        <Col sm="2">
                            <div>
                                <Link to='/'>
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                        </Col>
                        <Col sm="10">
                            <Menu />
                        </Col>
                    </Row>
                </Container>
            </header>
            <MenuUser />
            <section className={`bg-light pb-5`}>
                <Container className={`bg-white rounded position-relative ${styles.containerBase}`}>
                    <div className="px-2">
                        <Outlet />  {/* usado em rotas */}
                        {children} {/* usado em componente separado */}
                    </div>
                </Container>
            </section>
            <footer className="py-3">
                <Container>
                    <Row className="d-flex align-items-center font-13">
                        <Col lg="6" className="text-center text-lg-start">
                            <div>
                                2024© TOUR-IN
                            </div>
                        </Col>
                        <Col lg="6" className="text-center text-lg-end mt-2 mt-lg-0">
                            <div>
                                Ajuda
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default BaseLayout