import { useState, useEffect } from "react";

import { ReactComponentElement, lazy } from "react"
import { Navigate } from "react-router-dom"

// Layouts
import BlankLayout from "../layouts/BlankLayout"
import BaseLayout from "../layouts/BaseLayout"
import AuthLayout from "../layouts/auth";

/* páginas */
import authRoutes from "./auth";
import meusDadosRoutes from "./minhaConta";
import locaisRoutes from "./locais";
import cidadesRoutes from "./cidades";
import licencasRoutes from "./licencas";

// components
import { validateToken } from '../services/auth';

const getLayout: any = {
  blank: <BlankLayout />,
  auth: <AuthLayout />,
  base: <BaseLayout />
}
const defaultLayout = "base";

/* Página inicial */
const Home = lazy(() => import("../views/home"));

/* Página 404 */
const Pagina404 = lazy(() => import("../views/404"));

// ** Document title
const TemplateTitle = "%s - Tourin"

// ** Default Route
const DefaultRoute = "/home"

const Routes = [
  {
    path: "/home",
    element: <Home />,
    isPrivate: true,
  },
  ...authRoutes,
  ...meusDadosRoutes,
  ...locaisRoutes,
  ...cidadesRoutes,
  ...licencasRoutes
];

const RoutesLayout = () => {
  let LayoutRoutes = []

  LayoutRoutes.push({
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
    errorElement: <Pagina404 />,
  });

  if (Routes) {
    Routes.map((rota: any) => {
      let layoutRota = "";
      if (rota.meta !== undefined) {
        layoutRota = rota.meta.layout;
      } else {
        layoutRota = defaultLayout;
      }
      let page: React.ReactElement = getLayout[layoutRota];

      if (rota.isPrivate !== undefined && rota.isPrivate) {
        if (!validateToken()) {
          page = <Navigate to="/login" />;
        } else {
          page = getLayout[layoutRota as keyof typeof getLayout];
        }
      } else {
        page = getLayout[layoutRota as keyof typeof getLayout];
      }
      LayoutRoutes.push({
        path: rota.path,
        element: page,
        children: [
          {
            path: rota.path,
            element: rota.element,
          },
        ],
      })
    })
  }

  return LayoutRoutes;
}

const RoutesLayouts = RoutesLayout();

export { DefaultRoute, TemplateTitle, Routes, RoutesLayouts }