import { lazy } from "react"

/* Páginas */
const Login = lazy(() => import("../../views/auth/login"));
const EsqueciMinhaSenha = lazy(() => import("../../views/auth/esqueciMinhaSenha"));
const EmailEnviado = lazy(() => import("../../views/auth/emailEnviado"));
const NovaSenha = lazy(() => import("../../views/auth/novaSenha"));
const SenhaDefinida = lazy(() => import("../../views/auth/senhaDefinida"));

const authRoutes = [
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "auth"
    }
  },
  {
    path: "/esqueci-minha-senha",
    element: <EsqueciMinhaSenha />,
    meta: {
      layout: "auth"
    }
  },
  {
    path: "/email-enviado",
    element: <EmailEnviado />,
    meta: {
      layout: "auth"
    }
  },
  {
    path: "/nova-senha",
    element: <NovaSenha />,
    meta: {
      layout: "auth"
    }
  },
  {
    path: "/senha-definida",
    element: <SenhaDefinida />,
    meta: {
      layout: "auth"
    }
  },
];

export default authRoutes;