import { NavItem, NavLink, Nav } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

import styles from './menu.module.scss';

import menuList from '../../menu';

import { getUser } from "../../services/auth";

function Menu() {

  const user: any = getUser();
  const cidadeMapa = user.cidades.length > 0 ? user.cidades[0].mapa_virtual : "";

  const navigate = useNavigate();

  const menu = menuList;

  const handleItemClick = (url: string) => {
    navigate(url);
  }

  return (
    <>
      <Nav className={`${styles.menu} nav justify-content-end`}>
        {menu.map((item, index)=>(
        <NavItem key={index}>
          <NavLink className={`${styles.menuLink}`} onClick={() => handleItemClick(item.url)}>
            {item.nome}
          </NavLink>
        </NavItem>
        ))}
        {cidadeMapa !== "" &&
          <NavItem>
            <NavLink 
              className={`${styles.menuLink}`} 
              href={cidadeMapa} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Mapa Virtual
            </NavLink>
          </NavItem>
        }
      </Nav>
    </>
  );
}

export default Menu;
