export function setToken(token: string) {
    localStorage.setItem('@tourin-token', token);
}
export function getToken(): string | null {
    return localStorage.getItem('@tourin-token');
}
export function deleteToken() {
    localStorage.removeItem('@tourin-token');
}
export function setUser(user: any) {
    localStorage.setItem('@tourin-user', JSON.stringify(user));
}
export function getUser(): string | null {
    const user = localStorage.getItem('@tourin-user');
    return user ? JSON.parse(user) : null;
}
export function deleteUser() {
    localStorage.removeItem('@tourin-user');
}
export const logout = () => {
    localStorage.removeItem('@tourin-token');
    localStorage.removeItem('@tourin-user');
};

export function validateToken(): boolean {
    const token = getToken();

    if (!token) {
        return false;
    }

    const [header, payload, signature] = token.split('.');
    let parsedPayload;

    try {
        parsedPayload = JSON.parse(atob(payload));
    } catch (error) {
        return false;
    }

    if (new Date().getTime() / 1000 > parsedPayload.exp) {
        return false;
    }

    // Verifica se o usuário existe usando a função getUser
    const user = getUser();
  
    if (!user) {
      return false;
    }

    return true;
}

export function userTipo(): number {
    const user: any = getUser()
    if(!user){
        return 0;
    }else if(user.role.type === "gestor"){
        return 1;
    }else if(user.role.type === "empreendedor"){
        return 2;
    }else{
        return 0;
    }
}
export function validaUsuarioLogado() {
    if(validateToken()){
        window.location.href = './';
    }
}