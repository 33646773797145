import { lazy } from "react"

/* Páginas */
const LocaisLista = lazy(() => import("../../views/locais/lista"));
const LocalForm = lazy(() => import("../../views/locais/form"));
const LocalQuestionario = lazy(() => import("../../views/locais/questionario"));
const Visitantes = lazy(() => import("../../views/locais/visitantes"));
const Eventos = lazy(() => import("../../views/locais/eventos"));
const ExperienciasLista = lazy(() => import("../../views/locais/experiencias/listar"));
const ExperienciaForm = lazy(() => import("../../views/locais/experiencias/form"));
const ProgramacaoLista = lazy(() => import("../../views/locais/programacao/listar"));
const ProgramacaoForm = lazy(() => import("../../views/locais/programacao/form"));

const empresasRoutes = [
  {
    path: "/empresas",
    element: <LocaisLista />,
    isPrivate: true,
  },
  {
    path: "/empresas/form",
    element: <LocalForm />,
    isPrivate: true,
  },
  {
    path: "/empresas/form/:id",
    element: <LocalForm />,
    isPrivate: true,
  },
  {
    path: "/empresas/questionario/:id",
    element: <LocalQuestionario />,
    isPrivate: true,
  },
  {
    path: "/empresas/visitantes/:id",
    element: <Visitantes />,
    isPrivate: true,
  },
  {
    path: "/empresas/eventos/:id",
    element: <Eventos />,
    isPrivate: true,
  },
  {
    path: "/empresas/experiencias/:id",
    element: <ExperienciasLista />,
    isPrivate: true,
  },
  {
    path: "/empresas/experiencias/form/:local",
    element: <ExperienciaForm />,
    isPrivate: true,
  },
  {
    path: "/empresas/experiencias/form/:local/:id",
    element: <ExperienciaForm />,
    isPrivate: true,
  },
  {
    path: "/empresas/programacao/:id",
    element: <ProgramacaoLista />,
    isPrivate: true,
  },
  {
    path: "/empresas/programacao/form/:local",
    element: <ProgramacaoForm />,
    isPrivate: true,
  },
  {
    path: "/empresas/programacao/form/:local/:id",
    element: <ProgramacaoForm />,
    isPrivate: true,
  },
];

export default empresasRoutes;