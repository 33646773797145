import { MenuItem } from "./interface";

const menuGestor: MenuItem[] = [
    {
        nome: "Dashboard",
        url: "/home"
    },
    {
        nome: "Cidades",
        url: "/cidades"
    },
    {
        nome: "Licenças",
        url: "/licencas"
    },
];

export default menuGestor