import { userTipo } from "../services/auth";
import menuEmpreendedor from "./empreendedor";
import menuGestor from "./gestor";
import { MenuItem } from "./interface";

let objMenu: MenuItem[] = []
if (userTipo() === 1) {
    objMenu = [
        ...menuGestor
    ]
} else if (userTipo() === 2) {
    objMenu = [
        ...menuEmpreendedor
    ]
}
const menuList: MenuItem[] = objMenu;

export default menuList