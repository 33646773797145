import { lazy } from "react"

/* Páginas */
const MeusDados = lazy(() => import("../../views/minhaConta/meusDados"));

const meusDadosRoutes = [
    {
      path: "/meus-dados",
      element: <MeusDados />,
      isPrivate: true
    },
];

export default meusDadosRoutes;