import {
    createBrowserRouter 
} from "react-router-dom";

// ** GetRoutes
import { RoutesLayouts } from "./routes"

const Router = () => {

    const routes = createBrowserRouter([...RoutesLayouts]);

    return routes;
}

export default Router;