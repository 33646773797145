import { MenuItem } from "./interface";

const menuEmpreendedor: MenuItem[] = [
    {
        nome: "Dashboard",
        url: "/home"
    },
    {
        nome: "Empresas",
        url: "/empresas"
    },
];

export default menuEmpreendedor